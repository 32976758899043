import { forwardRef } from "react";

import { useHistory } from "react-router-dom";

import Box from "components/Box";
import { getEventUrl } from "core/urls";
import CpdIcon from "images/CpdIcon";
import Badge from "tpo/Badge";
import Group from "tpo/Group";
import Stack from "tpo/Stack";
import ButtonV2 from "v2/Buttons";

import classes from "./EventCard.module.css";

const EventCard = forwardRef(
  ({ shortDescription, title, startTime, location, type, cpdPoints, slug }, ref) => {
    const history = useHistory();

    return (
      <tr
        onClick={() => {
          history.push(getEventUrl(slug));
        }}
        className={classes.tr}
        ref={ref}
        style={{
          height: "100%"
        }}
      >
        <Box
          bg="white"
          as="td"
          pl={40}
          borderTopLeftRadius={20}
          borderBottomLeftRadius={20}
          py={40}
          height="100%"
        >
          <Stack height="100%">
            <Stack gap={8} mb={40}>
              <Box fontFamily="gilroyBold" fontSize={[24]}>
                {title}
              </Box>
              <Badge bg="purple" color="white" mr="auto">
                {type}
              </Badge>
            </Stack>
            <Group gap={10} mt="auto">
              <CpdIcon />
              <Box fontFamily="gilroyBold" fontSize={[18, 18, 24]}>
                {`${cpdPoints}pts`}
              </Box>
            </Group>
          </Stack>
        </Box>
        <Box bg="white" as="td" py={40} height="100%" px={20}>
          <Stack height="100%">
            <Box fontFamily="gilroyMedium" color="purple">
              {location}
            </Box>
            <Box fontFamily="gilroyBold" color="purple">
              {new Date(startTime).toLocaleString(undefined, {
                timeZoneName: "short"
              })}
            </Box>
          </Stack>
        </Box>
        <Box
          py={40}
          bg="white"
          as="td"
          pr={40}
          borderTopRightRadius={20}
          borderBottomRightRadius={20}
          height="100%"
        >
          <Stack height="100%">
            <Box className={classes.truncateText}>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
              incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
              exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure
              dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
              Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt
              mollit anim id est laborum.
            </Box>
            <ButtonV2
              color="dark"
              ml="auto"
              mt="auto"
              size="xs"
              sx={{
                px: 20,
                fontSize: 12
              }}
            >
              register
            </ButtonV2>
          </Stack>
        </Box>
      </tr>
    );
  }
);

export default EventCard;
