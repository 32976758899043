import { forwardRef } from "react";

import { useHistory } from "react-router-dom";

import Box from "components/Box";
import { getCourseUrl } from "core/urls";
import CpdIcon from "images/CpdIcon";
import Group from "tpo/Group";
import Stack from "tpo/Stack";
import ButtonV2 from "v2/Buttons";

const CourseCard = forwardRef(
  ({ id, slug, imageUrl, cpdPoints, title, shortDescription, subtitle }, ref) => {
    const history = useHistory();

    return (
      <Stack
        ref={ref}
        gap={0}
        borderRadius={5}
        style={{
          cursor: "pointer",
          overflow: "hidden"
        }}
        onClick={() => {
          history.push(getCourseUrl(slug));
        }}
      >
        <Box
          minHeight={300}
          style={{
            backgroundImage: `url(${imageUrl})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundSize: "cover"
          }}
        />
        <Stack bg="white" gap={40} padding={[20, 20, 28]} flexGrow={1}>
          <Stack gap={8}>
            {/* <Box color="anchorBlue" fontFamily="gilroyMedium" fontSize={18}>
            tag here
          </Box> */}
            <Box fontFamily="gilroyBold" fontSize={[18, 18, 24]}>
              {title}
            </Box>
            <Box fontFamily="gilroyMedium" fontSize={[14, 14, 16]}>
              {subtitle}
            </Box>
          </Stack>
          <Group justifyContent="space-between" mt="auto">
            <Group gap={10}>
              <CpdIcon />
              <Box fontFamily="gilroyBold" fontSize={[18, 18, 24]}>
                {`${cpdPoints}pts`}
              </Box>
            </Group>
            <ButtonV2
              color="dark"
              size="sm"
              sx={{
                py: 2
              }}
            >
              learn more
            </ButtonV2>
          </Group>
        </Stack>
      </Stack>
    );
  }
);

export default CourseCard;
