import { useEffect, useState } from "react";

import { useParams } from "react-router-dom";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";

import { gql, useMutation, useQuery } from "@apollo/client";
import Box from "components/Box";
import { DownloadCrossOriginFile } from "components/Links";
import { theme } from "core/theme";
import { COURSE_QUERY, COURSE_VIDEO_FIELDS } from "graphql/knowledgeCenter/webinars";
import Badge from "tpo/Badge";
import Center from "tpo/Center";
import ChevronComponent from "tpo/Chevron";
import Group from "tpo/Group";
import Stack from "tpo/Stack";
import ButtonV2, { ActionIcon } from "v2/Buttons";

import courseClasses from "./Course.module.css";
import FeaturedTests from "./FeaturedTests";
import VimeoComponent from "./VimeoComponent";
import classes from "./richText.module.css";

const VIDEO_STARTED = "VIDEO_STARTED";
const VIDEO_ENDED = "VIDEO_ENDED";

export const RECORD_VIDEO_EVENT_MUTATION = gql`
  mutation RecordVideoEventMutation($input: RecordVideoEventMutationInput!) {
    recordVideoEventMutation(input: $input) {
      courseVideo {
        ...CourseVideoFields
      }
    }
  }
  ${COURSE_VIDEO_FIELDS}
`;

export default function Course() {
  const { slug } = useParams();

  const { data: courseData, loading: loadingcourse } = useQuery(COURSE_QUERY, {
    variables: {
      slug
    }
  });

  const [recordVideoEvent] = useMutation(RECORD_VIDEO_EVENT_MUTATION);

  const course = courseData?.course;

  const [videoBeingWatched, setVideoBeingWatched] = useState(null);
  const [playVideo, setPlayVideo] = useState(false);

  useEffect(() => {
    if (course && videoBeingWatched === null) {
      if (course.videos.length > 0) {
        setVideoBeingWatched(course.videos[0].video);
      }
    }
  }, [course, videoBeingWatched]);

  const onVideoStarted = () => {
    if (videoBeingWatched.userStarted) return;
    recordVideoEvent({
      variables: {
        input: {
          event: VIDEO_STARTED,
          video: videoBeingWatched.id
        }
      }
    });
  };
  const onVideoEnded = () => {
    if (videoBeingWatched.userEnded) return;
    recordVideoEvent({
      variables: {
        input: {
          event: VIDEO_ENDED,
          video: videoBeingWatched.id
        }
      }
    });
  };

  if (!loadingcourse && !course) return <Redirect to="/404" />;

  const progress = course?.videos?.length
    ? course?.videos?.filter(v => v.video.userEnded)?.length / course?.videos?.length
    : undefined;

  return (
    <>
      <Box px={20} pt={theme.spacing.pageHeader.pt}>
        <Center stacked gap={28} pt={[60, 60, 80]} pb={60}>
          <Box textAlign="center" fontFamily="gilroyBold" fontSize={[40, 40, 68]} lineHeight="100%">
            {course?.title}
          </Box>
          <Box textAlign="center" fontFamily="gilroyRegular" fontSize={[24, 24, 32]}>
            {course?.subtitle}
          </Box>
        </Center>
      </Box>
      {!!course?.videos?.length && (
        <Box bg="haze" pt={60} px={20}>
          {!!videoBeingWatched && (
            <VimeoComponent
              height={720}
              url={videoBeingWatched.url}
              width={1274}
              play={playVideo}
              key={videoBeingWatched.url}
              onStart={onVideoStarted}
              onEnd={onVideoEnded}
            />
          )}
          <Stack stacked gap={40} pt={80} pb={120}>
            <Box fontFamily="gilroyBold" fontSize={[32, 32, 44]} textAlign="center">
              Course videos
            </Box>
            <Center alignItems="unset" gap={40} flexWrap="wrap">
              <Stack
                bg="white"
                borderRadius={20}
                px={[24, 24, 40]}
                py={40}
                width="100%"
                maxWidth={720}
                gap={40}
              >
                <Group alignItems="center" flexWrap="wrap" gap={20}>
                  <Box
                    fontFamily="gilroyBold"
                    fontSize={[12, 12, 16]}
                    textTransform="uppercase"
                    letterSpacing={2.8}
                  >
                    progress
                  </Box>
                  <Box flexGrow={1}>
                    <Box height={8} borderRadius={100} bg="purple" width={`${progress * 100}%`} />
                  </Box>
                  <Box fontFamily="gilroyMedium" fontSize={[14, 14, 16]} ml="auto">
                    {`${progress * 100}%`}
                  </Box>
                </Group>
                <Stack gap={0}>
                  {course?.videos?.map(courseVideo => (
                    <Group
                      py={28}
                      key={courseVideo.id}
                      className={courseClasses.videoSummary}
                      flexWrap="wrap"
                      alignItems="center"
                    >
                      <Group alignItems="center" gap={20}>
                        <img
                          src={courseVideo.video.thumbnailUrl}
                          width={100}
                          height={65}
                          alt="thumbnail"
                          style={{
                            backgroundColor: theme.colors.partners,
                            color: "transparent",
                            fontSize: 12,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center"
                          }}
                        />
                        <Box fontFamily="gilroyBold" fontSize={[16, 16, 18]}>
                          {courseVideo.title}
                        </Box>
                      </Group>
                      <Group alignItems="center" gap={16} ml="auto">
                        {courseVideo.video.userEnded && (
                          <Badge fontSize={9} py="8px" px={16} bg="green" color="white">
                            watched
                          </Badge>
                        )}
                        {courseVideo.video.userStarted && !courseVideo.video.userEnded && (
                          <Badge fontSize={9} py="8px" px={16} bg="dark" color="white">
                            started
                          </Badge>
                        )}
                        <ActionIcon
                          bg="dark"
                          variant="circle"
                          size={["sm", "sm", "md"]}
                          onClick={() => {
                            setVideoBeingWatched(courseVideo.video);
                            setPlayVideo(true);
                          }}
                        >
                          <ChevronComponent
                            style={{
                              marginLeft: 0
                            }}
                          />
                        </ActionIcon>
                      </Group>
                    </Group>
                  ))}
                </Stack>
              </Stack>
              <Stack
                bg="white"
                borderRadius={20}
                px={[24, 24, 40]}
                py={40}
                width="100%"
                maxWidth={720}
                gap={20}
              >
                <Box fontFamily="gilroyBold" fontSize={[28]}>
                  Related documents
                </Box>
                {course.documents.map(document => (
                  <Group key={document.id} alignItems="center" gap={20}>
                    <Box fontFamily="gilroyMedium" fontSize={16}>
                      {document.filename}
                    </Box>
                    <DownloadCrossOriginFile
                      fileUrl={document.url}
                      fileName={document.filename}
                      contentType={document.contentType}
                      trigger={
                        <ButtonV2
                          color="dark"
                          rightIcon={<ChevronComponent direction="bottom" />}
                          size="sm"
                        >
                          Download
                        </ButtonV2>
                      }
                      containerProps={{
                        ml: "auto"
                      }}
                    />
                  </Group>
                ))}
              </Stack>
            </Center>
          </Stack>
        </Box>
      )}
      <Box
        maxWidth={720}
        width="100%"
        mx="auto"
        px={20}
        pt={theme.spacing.section.pt}
        pb={theme.spacing.section.pb}
      >
        {!!course?.description && (
          <Stack>
            <Box fontFamily="gilroyBold" fontSize={[40, 40, 68]} textAlign="center">
              Course details
            </Box>
            <Box
              dangerouslySetInnerHTML={{ __html: course?.description }}
              className={classes.richText}
            />
          </Stack>
        )}
        {!!course?.testProducts?.length && <FeaturedTests testProducts={course.testProducts} />}
      </Box>
    </>
  );
}
