export default function PlayIcon({ size }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.2677 5.59354C14.3159 5.40796 14.3415 5.21339 14.3415 5.0129C14.3415 4.8124 14.3159 4.61784 14.2677 4.43225H20.7805C22.3969 4.43225 23.7073 5.73207 23.7073 7.33548V17.2064C23.7073 18.8099 22.3969 20.1097 20.7805 20.1097H3.21951C1.60307 20.1097 0.292683 18.8099 0.292683 17.2064V7.33548C0.292683 5.73207 1.60307 4.43225 3.21951 4.43225H9.7323C9.68415 4.61784 9.65854 4.8124 9.65854 5.0129C9.65854 5.21339 9.68415 5.40796 9.7323 5.59354H3.21951C2.24965 5.59354 1.46341 6.37343 1.46341 7.33548V17.2064C1.46341 18.1685 2.24965 18.9484 3.21951 18.9484H20.7805C21.7503 18.9484 22.5366 18.1685 22.5366 17.2064V7.33548C22.5366 6.37343 21.7503 5.59354 20.7805 5.59354H14.2677Z"
        fill="#1A1A1A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.8901 5.88386L13.9843 5.52118C14.0263 5.35903 14.0488 5.18878 14.0488 5.0129C14.0488 4.83702 14.0263 4.66676 13.9843 4.50462L13.8901 4.14193H20.7805C22.5586 4.14193 24 5.57173 24 7.33548V17.2064C24 18.9702 22.5586 20.4 20.7805 20.4H3.21951C1.44142 20.4 0 18.9702 0 17.2064V7.33548C0 5.57173 1.44142 4.14193 3.21951 4.14193H10.1099L10.0158 4.50462C9.97368 4.66676 9.95122 4.83702 9.95122 5.0129C9.95122 5.18878 9.97368 5.35903 10.0157 5.52118L10.1099 5.88386H3.21951C2.41129 5.88386 1.7561 6.53377 1.7561 7.33548V17.2064C1.7561 18.0081 2.41129 18.6581 3.21951 18.6581H20.7805C21.5887 18.6581 22.2439 18.0081 22.2439 17.2064V7.33548C22.2439 6.53377 21.5887 5.88386 20.7805 5.88386H13.8901ZM14.2677 5.59354H20.7805C21.7503 5.59354 22.5366 6.37343 22.5366 7.33548V17.2064C22.5366 18.1685 21.7503 18.9484 20.7805 18.9484H3.21951C2.24965 18.9484 1.46341 18.1685 1.46341 17.2064V7.33548C1.46341 6.37343 2.24965 5.59354 3.21951 5.59354H9.7323C9.70776 5.49896 9.68908 5.40206 9.67665 5.30322C9.6647 5.20811 9.65854 5.11122 9.65854 5.0129C9.65854 4.91458 9.6647 4.81768 9.67665 4.72257C9.68908 4.62374 9.70776 4.52683 9.7323 4.43225H3.21951C1.60307 4.43225 0.292683 5.73207 0.292683 7.33548V17.2064C0.292683 18.8099 1.60307 20.1097 3.21951 20.1097H20.7805C22.3969 20.1097 23.7073 18.8099 23.7073 17.2064V7.33548C23.7073 5.73207 22.3969 4.43225 20.7805 4.43225H14.2677C14.2922 4.52683 14.3109 4.62374 14.3234 4.72257C14.3353 4.81768 14.3415 4.91458 14.3415 5.0129C14.3415 5.11122 14.3353 5.20811 14.3234 5.30322C14.3109 5.40206 14.2922 5.49896 14.2677 5.59354Z"
        fill="#1A1A1A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 6.17419C12.6466 6.17419 13.1707 5.65426 13.1707 5.0129C13.1707 4.37153 12.6466 3.85161 12 3.85161C11.3534 3.85161 10.8293 4.37153 10.8293 5.0129C10.8293 5.65426 11.3534 6.17419 12 6.17419ZM12 7.33548C13.2932 7.33548 14.3415 6.29562 14.3415 5.0129C14.3415 3.73017 13.2932 2.69032 12 2.69032C10.7068 2.69032 9.65854 3.73017 9.65854 5.0129C9.65854 6.29562 10.7068 7.33548 12 7.33548Z"
        fill="#1A1A1A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 5.88386C12.4849 5.88386 12.878 5.49392 12.878 5.0129C12.878 4.53187 12.4849 4.14193 12 4.14193C11.5151 4.14193 11.122 4.53187 11.122 5.0129C11.122 5.49392 11.5151 5.88386 12 5.88386ZM14.6341 5.0129C14.6341 6.45596 13.4548 7.6258 12 7.6258C10.5452 7.6258 9.36585 6.45596 9.36585 5.0129C9.36585 3.56983 10.5452 2.39999 12 2.39999C13.4548 2.39999 14.6341 3.56983 14.6341 5.0129ZM13.1707 5.0129C13.1707 5.65426 12.6466 6.17419 12 6.17419C11.3534 6.17419 10.8293 5.65426 10.8293 5.0129C10.8293 4.37153 11.3534 3.85161 12 3.85161C12.6466 3.85161 13.1707 4.37153 13.1707 5.0129ZM14.3415 5.0129C14.3415 6.29562 13.2932 7.33548 12 7.33548C10.7068 7.33548 9.65854 6.29562 9.65854 5.0129C9.65854 3.73017 10.7068 2.69032 12 2.69032C13.2932 2.69032 14.3415 3.73017 14.3415 5.0129Z"
        fill="#1A1A1A"
      />
      <path
        d="M15.1543 11.867C15.4876 12.0594 15.4876 12.5406 15.1543 12.733L10.8043 15.2445C10.471 15.4369 10.0543 15.1964 10.0543 14.8115L10.0543 9.78852C10.0543 9.40362 10.471 9.16306 10.8043 9.35551L15.1543 11.867Z"
        fill="#1A1A1A"
      />
    </svg>
  );
}
