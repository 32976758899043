import { Fragment } from "react";

import { useParams } from "react-router-dom";

import { gql } from "@apollo/client";
import Box from "components/Box";
import Circle from "components/Circle";
import Grid from "components/Grid";
import OrderStatusBadges from "components/organisations/orders/ui/OrderStatusBadges";
import { theme } from "core/theme";
import { getClientManagementTabUrl, getClientManagementUserTestUrl } from "core/urls";
import { ORGANISATION_ORDER_DETAIL_FIELDS } from "graphql/organisations/queries/orders";
import { CORE_USER_THEME_FIELDS } from "graphql/tpo/common/types";
import useDocTitle from "hooks/use-doc-title";
import ChartIcon from "images/ChartIcon";
import DangerIcon from "images/Danger";
import TestTubeIcon from "images/TestTubeIcon";
import { ReactComponent as Pencil } from "images/pencil.svg";
import Center from "tpo/Center";
import ChevronComponent from "tpo/Chevron";
import Currency from "tpo/Currency";
import Group from "tpo/Group";
import LinkWrapper from "tpo/LinkWrapper";
import Stack from "tpo/Stack";
import WellnessScore from "tpo/WellnessScore";
import OrderIcon from "tpo/orders/OrderIcon";
import ButtonV2 from "v2/Buttons";

const DASHBOARD_PER_SNAPSHOT_FIELDS = gql`
  fragment DashboardPerSnapshotFields on PerSnapshotType {
    wellnessScorePanel {
      userTheme {
        ...CoreUserThemeFields
      }
      userTests {
        id
        name
      }
      hasSelfAssessment
    }
    selfAssessment {
      id
      inRangeCount
      outOfRangeCount
    }
    expiredMarkersPanel {
      userTests {
        id
        name
        expiredMarkersCount
        firstDatapointExpiry
      }
      selfAssessmentExpiredMarkersCount
      selfAssessment {
        id
        firstAnswerExpiry
      }
    }
    latestUserTest {
      id
      outOfRangeCount
      inRangeCount
      dataFiles {
        id
      }
    }
  }
  ${CORE_USER_THEME_FIELDS}
`;

// NB - latestOrder should be the latest cmp order
export const DASHBOARD_QUERY = gql`
  query DashboardQuery($userId: ID!) {
    user(userId: $userId) {
      patientDashboard {
        latestOrder {
          ...OrganisationOrderDetailFields
        }
        perFreshSnapshot {
          ...DashboardPerSnapshotFields
        }
        perSnapshot {
          ...DashboardPerSnapshotFields
        }
      }
    }
  }
  ${DASHBOARD_PER_SNAPSHOT_FIELDS}
  ${ORGANISATION_ORDER_DETAIL_FIELDS}
`;

function DashboardChartPanel({ icon, title, subtitle, bars }) {
  return (
    <Stack
      pt={theme.spacing.panelBox.pt}
      pb={theme.spacing.panelBox.pb}
      px={theme.spacing.panelBox.px}
      borderRadius={theme.radius.panelBox}
      bg="white"
      gap={[20, 20, 40]}
    >
      <Group alignItems="center" gap={20}>
        {icon}
        <Box fontFamily="gilroyBold" fontSize={[24, 24, 32]}>
          {title}
        </Box>
      </Group>
      <Box fontFamily="gilroyBold" fontSize={[18, 18, 24]} textAlign="center">
        {subtitle}
      </Box>
      <Grid gridTemplateColumns={bars.map(() => "1fr").join(" ")} gap={10} mt="auto">
        {bars.map(bar => (
          <Fragment key={bar.title}>
            <Box
              fontSize={[24, 24, 32]}
              fontFamily="gilroyBold"
              style={{
                gridRowStart: 1
              }}
            >
              {bar.score}
            </Box>
            <Box
              bg={bar.bg}
              height={bar.value}
              style={{
                gridRowStart: 2
              }}
              mt="auto"
              borderRadius={5}
            />
            <Box
              style={{
                gridRowStart: 3
              }}
              textAlign="center"
              fontFamily="gilroyBold"
              fontSize={[14, 14, 16]}
            >
              {bar.title}
            </Box>
          </Fragment>
        ))}
      </Grid>
    </Stack>
  );
}

export default function Dashboard({
  wellnessScorePanel,
  latestOrderPanel,
  selfAssessmentPanel,
  expiredMarkersPanel,
  latestTestResultsPanel
}) {
  useDocTitle("Patient's Dashboard");

  const { patientId } = useParams();

  let latestTestResultsPanelComponent = (
    <Stack
      pt={theme.spacing.panelBox.pt}
      pb={theme.spacing.panelBox.pb}
      px={theme.spacing.panelBox.px}
      borderRadius={theme.radius.panelBox}
      bg="white"
      gap={[20, 20, 40]}
    >
      <Group alignItems="center" gap={20}>
        <Circle bg="transparent" size={60} withBorder borderWidth={2}>
          <Pencil fill="black" width={30} />
        </Circle>
        <Box fontFamily="gilroyBold" fontSize={[24, 24, 32]}>
          Latest test results
        </Box>
      </Group>
      <Box fontFamily="gilroyBold" fontSize={[18, 18, 24]} textAlign="center">
        No available test results
      </Box>
      <Box fontFamily="gilroyMedium" fontSize={[16, 16, 18]} textAlign="center" mt={[-2, -2, -4]}>
        This patient has yet to receive any test results
      </Box>
    </Stack>
  );

  if (latestTestResultsPanel) {
    if (latestTestResultsPanel.inRangeCount + latestTestResultsPanel.outOfRangeCount > 0) {
      latestTestResultsPanelComponent = (
        <DashboardChartPanel
          icon={
            <Circle bg="transparent" size={60} withBorder borderWidth={2}>
              <ChartIcon size={30} />
            </Circle>
          }
          title="Latest test results"
          subtitle={`${
            latestTestResultsPanel.inRangeCount + latestTestResultsPanel.outOfRangeCount
          } markers`}
          bars={[
            {
              score: latestTestResultsPanel.inRangeCount,
              value:
                (latestTestResultsPanel.inRangeCount /
                  (latestTestResultsPanel.outOfRangeCount + latestTestResultsPanel.inRangeCount)) *
                120,
              bg: "green",
              title: "In range"
            },
            {
              score: latestTestResultsPanel.outOfRangeCount,
              value:
                (latestTestResultsPanel.outOfRangeCount /
                  (latestTestResultsPanel.outOfRangeCount + latestTestResultsPanel.inRangeCount)) *
                120,
              bg: "orange",
              title: "Out of range"
            }
          ]}
          emptyTitle="No available test results"
          emptySubtitle="This patient has yet to receive any test results"
        />
      );
    } else if (
      latestTestResultsPanel.inRangeCount + latestTestResultsPanel.outOfRangeCount === 0 &&
      latestTestResultsPanel.dataFiles.length
    ) {
      latestTestResultsPanelComponent = (
        <Stack
          pt={theme.spacing.panelBox.pt}
          pb={theme.spacing.panelBox.pb}
          px={theme.spacing.panelBox.px}
          borderRadius={theme.radius.panelBox}
          bg="white"
          gap={[20, 20, 40]}
        >
          <Group alignItems="center" gap={20}>
            <Circle bg="transparent" size={60} withBorder borderWidth={2}>
              <Pencil fill="black" width={30} />
            </Circle>
            <Box fontFamily="gilroyBold" fontSize={[24, 24, 32]}>
              Latest test results
            </Box>
          </Group>
          <Box fontFamily="gilroyBold" fontSize={[18, 18, 24]} textAlign="center">
            View latest PDF
          </Box>
          <ButtonV2
            as={LinkWrapper}
            color="green"
            rightIcon={<ChevronComponent />}
            size={["sm", "sm", "md"]}
            mx="auto"
            mt={[-2, -2, -4]}
            to={getClientManagementUserTestUrl(patientId, latestTestResultsPanel?.id)}
          >
            view results
          </ButtonV2>
        </Stack>
      );
    }
  }

  return (
    <>
      <Box
        pt={theme.spacing.section.pt}
        pb={theme.spacing.section.pb}
        mx="auto"
        maxWidth={1280}
        px={20}
      >
        <Grid gap={20} gridTemplateColumns={["1fr", "1fr", "1fr 1fr"]}>
          <Box
            pt={theme.spacing.panelBox.pt}
            pb={theme.spacing.panelBox.pb}
            px={theme.spacing.panelBox.px}
            borderRadius={theme.radius.panelBox}
            background="#5220DD linear-gradient(137deg, #5220DD 0%, #9747FF 100%)"
          >
            <Stack gap={40}>
              <Center>
                <WellnessScore
                  scoreInterpretations={
                    wellnessScorePanel?.userTheme
                      ? wellnessScorePanel.userTheme.scoreInterpretations
                      : [
                          {
                            name: "No Results",
                            score: 0
                          }
                        ]
                  }
                  wellnessScore={
                    wellnessScorePanel?.userTheme
                      ? wellnessScorePanel.userTheme.invertedScore * 100
                      : 0
                  }
                />
              </Center>
              {wellnessScorePanel?.userTheme?.invertedScore * 100 > 0 && (
                <Box display="flex" justifyContent="center" alignItems="center">
                  <ButtonV2
                    as={LinkWrapper}
                    color="white"
                    variant="outline"
                    rightIcon={<ChevronComponent />}
                    size={["sm", "sm", "md"]}
                    to={getClientManagementTabUrl(patientId, "wellness")}
                  >
                    view results
                  </ButtonV2>
                </Box>
              )}
              {!!wellnessScorePanel && (
                <Stack gap={10}>
                  <Box color="white" fontFamily="gilroyBold" fontSize={[18, 18, 24]}>
                    Tests included
                  </Box>
                  <Grid gridTemplateColumns={["1fr 1fr"]} gridRowGap={2}>
                    {wellnessScorePanel?.hasSelfAssessment && (
                      <Group
                        color="white"
                        fontFamily="gilroyMedium"
                        fontSize={[14, 14, 16]}
                        alignItems="center"
                        gap={16}
                      >
                        <Pencil fill="white" width={24} />
                        Self assessment
                      </Group>
                    )}
                    {wellnessScorePanel?.userTests.map(test => (
                      <Group
                        key={test.id}
                        color="white"
                        fontFamily="gilroyMedium"
                        fontSize={[14, 14, 16]}
                        alignItems="center"
                        gap={16}
                      >
                        <OrderIcon color="white" width={24} />
                        {test.name}
                      </Group>
                    ))}
                  </Grid>
                </Stack>
              )}
            </Stack>
          </Box>
          <Stack
            pt={theme.spacing.panelBox.pt}
            pb={theme.spacing.panelBox.pb}
            px={theme.spacing.panelBox.px}
            borderRadius={theme.radius.panelBox}
            bg="white"
            gap={[20, 20, 40]}
          >
            <Group alignItems="center" gap={20}>
              <Circle bg="transparent" size={60} withBorder borderWidth={2}>
                <TestTubeIcon size={30} />
              </Circle>
              <Box fontFamily="gilroyBold" fontSize={[24, 24, 32]}>
                Latest order
              </Box>
            </Group>
            {latestOrderPanel ? (
              <Stack gap={20}>
                <Group gap={20} flexWrap="wrap" justifyContent="space-between">
                  <Group gap={20}>
                    <Box as="span">
                      {new Date(latestOrderPanel.checkoutDate).toLocaleDateString()}
                    </Box>
                    <Box as="span">{latestOrderPanel.id}</Box>
                  </Group>
                  <OrderStatusBadges order={latestOrderPanel} />
                </Group>
                <Stack gap={20}>
                  {latestOrderPanel.testItems.map(ti => (
                    <Group key={ti.id} alignItems="center" justifyContent="space-between">
                      <Box as="span" fontFamily={[16, 16, 18]}>
                        {ti.nameInBasket}
                      </Box>
                      <Currency
                        fontFamily="gilroyBold"
                        symbol={latestOrderPanel.sourceBasket.currencySymbol}
                        value={ti.price}
                      />
                    </Group>
                  ))}
                  {latestOrderPanel.supplementItems.map(si => (
                    <Group key={si.id} alignItems="center" justifyContent="space-between">
                      <Box as="span" fontFamily={[16, 16, 18]}>
                        {si.nameInBasket}
                      </Box>
                      <Currency
                        fontFamily="gilroyBold"
                        symbol={latestOrderPanel.sourceBasket.currencySymbol}
                        value={si.price}
                      />
                    </Group>
                  ))}
                </Stack>
              </Stack>
            ) : (
              <Center fontFamily="gilroyBold" fontSize={[18, 18, 24]}>
                This patient has no orders
              </Center>
            )}
          </Stack>
          {expiredMarkersPanel?.selfAssessmentExpiredMarkersCount > 0 &&
            !!expiredMarkersPanel.userTests.length && (
              <Stack
                pt={theme.spacing.panelBox.pt}
                pb={theme.spacing.panelBox.pb}
                px={theme.spacing.panelBox.px}
                borderRadius={theme.radius.panelBox}
                bg="white"
                gap={[20, 20, 40]}
              >
                <Group alignItems="center" gap={20}>
                  <Circle bg="transparent" size={60} withBorder borderWidth={2}>
                    <DangerIcon size={26} />
                  </Circle>
                  <Box fontFamily="gilroyBold" fontSize={[24, 24, 32]}>
                    Expired markers
                  </Box>
                </Group>
                {expiredMarkersPanel && (
                  <Stack>
                    {!!expiredMarkersPanel.selfAssessment && (
                      <Group
                        fontFamily="gilroyMedium"
                        fontSize={[14, 14, 16]}
                        alignItems="center"
                        gap={16}
                      >
                        <Pencil fill="dark" width={24} />
                        <Box as="span">
                          Self assessment{" - "}
                          {expiredMarkersPanel.selfAssessmentExpiredMarkersCount ? (
                            <Box as="span" color="red">
                              {expiredMarkersPanel.selfAssessmentExpiredMarkersCount} markers
                              expired
                            </Box>
                          ) : (
                            <Box as="span">{`Due to expire ${new Date(
                              expiredMarkersPanel.selfAssessment.firstAnswerExpiry
                            ).toLocaleDateString()}`}</Box>
                          )}
                        </Box>
                      </Group>
                    )}
                    {expiredMarkersPanel.userTests
                      .filter(
                        ut =>
                          ut.expiredMarkersCount ||
                          new Date(ut.firstDatapointExpiry).getFullYear() !== 9999
                      )
                      .map(ut => (
                        <Group
                          fontFamily="gilroyMedium"
                          fontSize={[14, 14, 16]}
                          alignItems="center"
                          gap={16}
                          key={ut.id}
                        >
                          <OrderIcon color="dark" width={24} />
                          <Box as="span">
                            {ut.name}
                            {" - "}
                            {ut.expiredMarkersCount ? (
                              <Box as="span" color="red">
                                {ut.expiredMarkersCount} markers expired
                              </Box>
                            ) : (
                              <Box as="span">{`Due to expire ${new Date(
                                ut.firstDatapointExpiry
                              ).toLocaleDateString()}`}</Box>
                            )}
                          </Box>
                        </Group>
                      ))}
                  </Stack>
                )}
              </Stack>
            )}
          {selfAssessmentPanel &&
          selfAssessmentPanel.inRangeCount + selfAssessmentPanel.outOfRangeCount > 0 ? (
            <DashboardChartPanel
              icon={
                <Circle bg="transparent" size={60} withBorder borderWidth={2}>
                  <Pencil fill="black" width={30} />
                </Circle>
              }
              title="Self assessment"
              subtitle={`${
                selfAssessmentPanel.inRangeCount + selfAssessmentPanel.outOfRangeCount
              } answers`}
              bars={[
                {
                  score: selfAssessmentPanel.inRangeCount,
                  value:
                    (selfAssessmentPanel.inRangeCount /
                      (selfAssessmentPanel.outOfRangeCount + selfAssessmentPanel.inRangeCount)) *
                    120,
                  bg: "green",
                  title: "In range"
                },
                {
                  score: selfAssessmentPanel.outOfRangeCount,
                  value:
                    (selfAssessmentPanel.outOfRangeCount /
                      (selfAssessmentPanel.outOfRangeCount + selfAssessmentPanel.inRangeCount)) *
                    120,
                  bg: "orange",
                  title: "Out of range"
                }
              ]}
              emptyTitle="Not yet completed"
              emptySubtitle="This patient has yet to complete their self assessment questionnaire"
            />
          ) : (
            <Stack
              pt={theme.spacing.panelBox.pt}
              pb={theme.spacing.panelBox.pb}
              px={theme.spacing.panelBox.px}
              borderRadius={theme.radius.panelBox}
              bg="white"
              gap={[20, 20, 40]}
            >
              <Group alignItems="center" gap={20}>
                <Circle bg="transparent" size={60} withBorder borderWidth={2}>
                  <Pencil fill="black" width={30} />
                </Circle>
                <Box fontFamily="gilroyBold" fontSize={[24, 24, 32]}>
                  Self assessment
                </Box>
              </Group>
              <Box fontFamily="gilroyBold" fontSize={[18, 18, 24]} textAlign="center">
                Not yet completed
              </Box>
              <Box
                fontFamily="gilroyMedium"
                fontSize={[16, 16, 18]}
                textAlign="center"
                mt={[-2, -2, -4]}
              >
                This patient has yet to complete their self assessment questionnaire
              </Box>
            </Stack>
          )}
          {latestTestResultsPanelComponent}
        </Grid>
      </Box>
    </>
  );
}
